import React from 'react'
import { LinkWithIcon } from '../../components/atoms/Link/LinkWithIcon'
import { ENGLISH_TITLE } from '../../components/data/English'
import { SEO } from '../../components/data/SEO'
import { FormStatus } from '../../components/organisms/Form/FormStatus'
import { LayoutNoSideNav } from '../../components/organisms/Layout/LayoutNoSideNav'

const Page = () => {
  return (
    <LayoutNoSideNav
      heading="お問い合わせ完了"
      headingEnglish={ENGLISH_TITLE.Contact}
      BreadcrumbData={[
        { title: 'お問い合わせ', url: '/contact' },
        { title: 'お問い合わせ完了', url: '' },
      ]}
    >
      <div className="pc:py-12 pc:px-8 bg-white">
        <FormStatus status={'送信完了'} />
        <div className="pt-12">
          <p className="text-center font-bold text-md text-primary">
            お問い合わせが完了しました
          </p>
          <p className="text-center mt-2 text-md leading-normal">
            お問い合わせいただき、ありがとうございます。
            <br className="hidden pc:block" />
            内容を確認次第、担当者からご連絡差し上げまのでしばらくお待ちください。
          </p>
          <div className="mt-6 text-center">
            <LinkWithIcon iconType="arrowReverse" href="/">
              HOMEへ戻る
            </LinkWithIcon>
          </div>
        </div>
      </div>
    </LayoutNoSideNav>
  )
}

export default Page

export const Head = () => <SEO title="お問い合わせ完了" url="/contact/finish" />
