// import { css } from '@emotion/react'
import { ReactNode } from 'react'
import { hoverStyleColorRed } from '../../styles/hover'
import { LinkBase } from './LinkBase'

interface Props {
  href: string
  children: ReactNode
  iconType: 'external' | 'location' | 'arrow' | 'arrowReverse'
}

/**
 * テキストリンク
 * アイコン付き
 * @param {string} href リンクです。
 * @param {ReactNode} children テキストです。
 * @param {'external' | 'location' | 'arrow' | 'arrowReverse'} iconType アイコンの種類
 */
/** */
export const LinkWithIcon = ({ href, children, iconType }: Props) => {
  return (
    <LinkBase
      css={hoverStyleColorRed}
      className={`duration-default inline-flex items-center text-text10 text-md leading-tight ${
        iconType === 'external' || iconType === 'arrow'
          ? 'flex-row'
          : 'flex-row-reverse'
      }`}
      href={href}
    >
      <span
        className={`${
          iconType === 'external' || iconType === 'arrow'
            ? 'pr-3'
            : 'pl-1 pc:pl-2'
        }`}
      >
        {children}
      </span>
      <span>
        {iconType === 'external' && (
          <svg
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.49902 0.999885C6.49902 0.723739 6.72288 0.499878 6.99903 0.499878H11.4991C11.7752 0.499878 11.9991 0.723739 11.9991 0.999885V5.49995C11.9991 5.77609 11.7752 5.99995 11.4991 5.99995C11.2229 5.99995 10.9991 5.77609 10.9991 5.49995V1.49989H6.99903C6.72288 1.49989 6.49902 1.27603 6.49902 0.999885Z"
              fill="#CC050B"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 2.99988C0 2.72374 0.223861 2.49988 0.500007 2.49988H4.50006C4.77621 2.49988 5.00007 2.72374 5.00007 2.99988C5.00007 3.27603 4.77621 3.49989 4.50006 3.49989H1.00001V11.5H9.00012V7.99995C9.00012 7.72381 9.22398 7.49995 9.50013 7.49995C9.77627 7.49995 10.0001 7.72381 10.0001 7.99995V12C10.0001 12.2762 9.77627 12.5 9.50013 12.5H0.500007C0.223861 12.5 0 12.2762 0 12V2.99988Z"
              fill="#CC050B"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.3527 1.14633C11.5479 1.34159 11.5479 1.65818 11.3527 1.85344L5.85259 7.35352C5.65732 7.54878 5.34074 7.54878 5.14547 7.35352C4.95021 7.15825 4.95021 6.84167 5.14547 6.6464L10.6455 1.14633C10.8408 0.951062 11.1574 0.951062 11.3527 1.14633Z"
              fill="#CC050B"
            />
          </svg>
        )}
        {iconType === 'location' && (
          <svg
            width="13"
            height="19"
            viewBox="0 0 13 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.5 1.39732C5.00595 1.39732 3.57309 2.01182 2.51663 3.10563C1.46018 4.19945 0.866667 5.68299 0.866667 7.22988C0.866667 8.27646 1.21652 9.4537 1.77202 10.6537C2.32508 11.8484 3.06684 13.0336 3.81572 14.0876C4.56366 15.1402 5.31259 16.0534 5.87493 16.7037C6.12685 16.995 6.34088 17.233 6.5 17.4067C6.65912 17.233 6.87315 16.995 7.12507 16.7037C7.68741 16.0534 8.43634 15.1402 9.18428 14.0876C9.93316 13.0336 10.6749 11.8484 11.228 10.6537C11.7835 9.4537 12.1333 8.27646 12.1333 7.22988C12.1333 5.68299 11.5398 4.19945 10.4834 3.10563C9.42691 2.01182 7.99405 1.39732 6.5 1.39732ZM6.5 18.0515C6.19425 18.3695 6.19417 18.3694 6.19407 18.3693L6.1927 18.3679L6.18896 18.364L6.17505 18.3495C6.16296 18.3369 6.1453 18.3184 6.12244 18.2943C6.07673 18.246 6.01021 18.1751 5.92586 18.0836C5.75719 17.9007 5.51705 17.635 5.22924 17.3022C4.65407 16.6371 3.88634 15.7012 3.11762 14.6193C2.34982 13.5388 1.57492 12.304 0.990481 11.0415C0.40848 9.78427 0 8.46697 0 7.22988C2.58287e-08 5.445 0.68482 3.73323 1.90381 2.47114C3.12279 1.20904 4.77609 0.5 6.5 0.5C8.22391 0.5 9.87721 1.20904 11.0962 2.47114C12.3152 3.73323 13 5.445 13 7.22988C13 8.46697 12.5915 9.78427 12.0095 11.0415C11.4251 12.304 10.6502 13.5388 9.88238 14.6193C9.11366 15.7012 8.34593 16.6371 7.77076 17.3022C7.48295 17.635 7.24281 17.9007 7.07414 18.0836C6.98979 18.1751 6.92327 18.246 6.87756 18.2943C6.8547 18.3184 6.83704 18.3369 6.82495 18.3495L6.81104 18.364L6.8073 18.3679L6.80593 18.3693C6.80583 18.3694 6.80575 18.3695 6.5 18.0515ZM6.5 18.0515L6.80593 18.3693C6.63686 18.5436 6.36314 18.5436 6.19407 18.3693L6.5 18.0515Z"
              fill="#CC050B"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.5 5.79418C5.67157 5.79418 5 6.50525 5 7.38241C5 8.25957 5.67157 8.97065 6.5 8.97065C7.32843 8.97065 8 8.25957 8 7.38241C8 6.50525 7.32843 5.79418 6.5 5.79418ZM4 7.38241C4 5.92048 5.11929 4.73535 6.5 4.73535C7.88071 4.73535 9 5.92048 9 7.38241C9 8.84434 7.88071 10.0295 6.5 10.0295C5.11929 10.0295 4 8.84434 4 7.38241Z"
              fill="#CC050B"
            />
          </svg>
        )}
        {iconType === 'arrow' && (
          <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 3.5L0.25 6.9641L0.25 0.0358982L10 3.5Z"
              fill="#CC050B"
            />
          </svg>
        )}
        {iconType === 'arrowReverse' && (
          <svg
            width="10"
            height="9"
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1380_30437"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="10"
              height="9"
            >
              <rect
                width="10"
                height="8"
                transform="matrix(-1 0 0 1 10 0.5)"
                fill="#D9D9D9"
              />
            </mask>
            <g mask="url(#mask0_1380_30437)">
              <path
                d="M2.62268e-07 4.5L9.75 7.9641L9.75 1.0359L2.62268e-07 4.5Z"
                fill="#CC050B"
              />
            </g>
          </svg>
        )}
      </span>
    </LinkBase>
  )
}
